import React from 'react'
import Layout from '../components/layout'
import { Container, Box, Theme, Paper, Typography } from '@material-ui/core'
import { graphql } from 'gatsby'

import { HelmetProvider } from 'react-helmet-async'
import { makeStyles } from '@material-ui/styles'
import { HomepageQuery } from '../../@types/graphql-types'

import Hero from '../components/Hero'
import TreatmentsCards from '../components/TreatmentsCards'
import Footer from '../components/Footer'
import TherapistsCards from '../components/TherapistsCards'
import CookieConsent from 'react-cookie-consent'
import ReactPlayer from 'react-player'
import { IconGrid } from '../components/IconGrid'
import SEO from '../components/SEO'
interface HomePageProps {
  data: HomepageQuery
}
const useStyles = makeStyles((theme: Theme) => ({
  backgroundImage: (props: HomePageProps) =>
    props.data.sanitySiteSettings.heroImageLight?.asset.fluid.src
      ? {
          height: '80vh',
          background:
            theme.palette.type === 'dark'
              ? `linear-gradient(rgba(255,255,255,0.1),black),  url(${props.data.sanitySiteSettings.heroImage.asset.fluid.src}) no-repeat top left`
              : `linear-gradient(rgba(254, 254, 254, 0.5),rgba(254, 254, 254, 0.5)), url(${props.data.sanitySiteSettings.heroImageLight.asset.fluid.src})`,
          backgroundPositionX: 0,
          backgroundPositiony: 0,
          [theme.breakpoints.down('xs')]: {
            backgroundPositionX: 'center',
          },
          top: 0,
          backgroundSize: 'cover',
          '&:video': {
            objectFit: 'cover',
            filter: 'blur(5px)',
          },
        }
      : undefined,

  bg: {
    height: '80vh',
    width: '100vw',
    top: 0,
    '&:video': {
      objectFit: 'cover',
    },
  },
  logo: {
    alignSelf: 'center',
  },
  centerText: {
    height: '80vh',
    alignItems: 'center',
    display: 'flex',
  },
  people: {
    margin: theme.spacing(5, 0, 5, 0),
  },
  padding: {
    paddingBottom: theme.spacing(2),
  },
}))
const HomePage: React.FC<HomePageProps> = (props) => {
  const classes = useStyles(props)
  return (
    <HelmetProvider>
      {/* <Meta /> */}
      <Layout>
        <SEO
          title="Home"
          image={`${props.data.sanitySiteSettings.heroImage.asset.fluid}`}
        />
        <Box className={classes.backgroundImage}>
          {/* <ReactPlayer
            url="https://moefit.s3.eu-central-1.amazonaws.com/Comp_1_3.mp4"
            playing
            loop
            controls={false}
            muted
            width="100%"
            height="100%"
            
          /> */}
          <Container maxWidth="lg" className={classes.centerText}>
            <Hero />
          </Container>
        </Box>

        <Container maxWidth="lg">
          <TreatmentsCards treatments={props.data.allSanityTreatment.edges} />
        </Container>

        <Box className={classes.people}>
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
              Onze kinesisten/ secretaresse
            </Typography>
            <TherapistsCards therapists={props.data.allSanityPerson.edges} />
          </Container>
        </Box>
        <Paper>
          <Container maxWidth="lg">
            <IconGrid />
          </Container>
        </Paper>

        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Aanvaarden"
          declineButtonText="Weigeren"
          cookieName="gatsby-gdpr-google-analytics"
          enableDeclineButton
        >
          MoeFit maakt gebruik van cookies om je gebruikerservaring te
          verbeteren.
        </CookieConsent>
      </Layout>
    </HelmetProvider>
  )
}

export const pageQuery = graphql`
  query Homepage {
    sanitySiteSettings {
      siteUrl
      title
      description
      logo {
        asset {
          fixed(width: 125) {
            src
          }
        }
      }
      heroImage {
        asset {
          fluid(maxWidth: 2160) {
            src
          }
        }
      }
      heroImageLight {
        asset {
          fluid(maxWidth: 2160) {
            src
          }
        }
      }
    }
    allSanityTreatment(sort: { fields: order, order: ASC }, limit: 7) {
      edges {
        node {
          order
          title
          slug {
            current
          }
          shortDescription
          background {
            asset {
              fluid(maxWidth: 250) {
                ...GatsbySanityImageFluid
              }
            }
          }
          subTitle
          _rawBody
          people {
            fullName
            slug {
              current
            }
          }
        }
      }
    }
    allSanityPerson(sort: { fields: order, order: ASC }) {
      edges {
        node {
          order
          avatar {
            asset {
              fixed(width: 90) {
                ...GatsbySanityImageFixed
              }
            }
          }
          content {
            title
            _rawBody
          }
          slug {
            current
          }
          conventionated
          social {
            facebook
            instagram
            twitter
          }
          treatments {
            slug {
              current
            }
            title
          }
          fullName
        }
      }
    }
  }
`
export default HomePage
