import React from 'react'
import {Grid, Typography, Button, makeStyles, SvgIcon} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LogoTitle from '../../content/assets/logo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(7),
    },
  },
  heroTitle: {
    display: 'flex',
    fontWeight: 300,
  },
  heroTitleText: {
    display: 'none',
  },
  heroTitleLogo: {
    // fontSize: theme.typography.h1.fontSize,
    fontSize: 'inherit',
    width: '293px',
    height: '112px',
  },
  subTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 300,
    fontStyle: 'italic',
  },
  heroContent: {
    lineHeight: '1.8',
    fontSize: theme.spacing(2) * 1.3,
  },
  heroImage: {
    width: '100%',
  },
  heroButton: {
    textTransform: 'none',
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
    '& Button:not(:first-child)': {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
      },

      marginLeft: 0,
    },
    '& Button': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: theme.spacing(2),
      },
    },
  },
}))

const Hero: React.FC = () => {
  const classes = useStyles(undefined)
  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      // data-sal="slide-up"
      // data-sal-delay="300"
      // data-sal-easing="ease"
    >
      <Grid item sm={12} xs={12} md={6} lg={6}>
        <Typography variant="h1" className={classes.heroTitle}>
          <span className={classes.heroTitleText}>MoeFit</span>
          <SvgIcon component={LogoTitle} viewBox="0 0 249 63" className={classes.heroTitleLogo}/>
        </Typography>
        <Typography variant="h2" className={classes.subTitle}>
          Kinesitherapie
        </Typography>
        <div className={classes.buttonGroup}>
          <Button
            color={'secondary'}
            variant="contained"
            rel="noopener nofollow"
            size="large"
            href={`https://altagenda.crossuite.com/MoeFit/`}
            target="_blank"
            endIcon={<AddIcon />}
            className={classes.heroButton}
          >
            Afspraak maken
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default Hero
