/* eslint-disable react/display-name */
import React from 'react'
import { makeStyles, Typography, Box, Grid } from '@material-ui/core'
import BlockContent from './block-content'
import Img, { FluidObject } from 'gatsby-image'
import {
  GatsbySanityImageFluidFragment,
  Maybe,
  SanityPage,
  SanityPerson,
  SanitySlug,
  SanitySocial,
  SanityTreatment,
  Scalars,
} from '../../@types/graphql-types'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    overflow: 'hidden',
    position: 'relative',
    transition: 'ease in .2s',
    display: 'flex',
    flexGrow: 1,
  },
  cardContent: {
    padding: theme.spacing(3),
    background: theme.palette.background.paper,
    borderRadius: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    backgroundImage:
      'linear-gradient(270deg, #0074E0 0%, #3083CF 39.06%, #309FCF 96.88%);',
    width: '100%',
  },
  image: {
    width: `10%`,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    float: 'left',
    shapeOutside: 'circle(50%)',
  },
}))

interface TherapistCards {
  therapists: Array<{
    node: Pick<SanityPerson, 'fullName'> & {
      avatar?: Maybe<{
        asset?: Maybe<{ fluid?: Maybe<GatsbySanityImageFluidFragment> }>
      }>
      content?: Maybe<Pick<SanityPage, 'title' | '_rawBody'>>
      slug?: Maybe<Pick<SanitySlug, 'current'>>
      social?: Maybe<Pick<SanitySocial, 'facebook' | 'instagram' | 'twitter'>>
      conventionated?: Maybe<Scalars['Boolean']>
      treatments?: Maybe<
        Array<
          Maybe<
            Pick<SanityTreatment, 'title'> & {
              slug?: Maybe<Pick<SanitySlug, 'current'>>
            }
          >
        >
      >
    }
  }>
}
const TherapistsCards: React.FC<TherapistCards> = ({ therapists }) => {
  const classes = useStyles(undefined)

  return (
    <Grid container spacing={4} alignItems="stretch">
      {therapists.map((edge, key) => {
        return (
          <Grid key={key} item xs={12} sm={6} md={4} className={classes.card}>
            <Box className={classes.cardContent}>
              <Box marginBottom={1}>
                {edge.node.avatar && (
                  <Img
                    className={classes.image}
                    fixed={edge.node.avatar.asset.fixed}
                  />
                )}
                <Typography variant={'h5'} component={'h1'}>
                  {edge.node.fullName}
                </Typography>
                <Typography variant={'subtitle1'} style={{ color: `#0a78dd` }}>
                  {edge.node.content.title}
                </Typography>
                {edge.node.content && (
                  <BlockContent
                    blocks={edge.node.content._rawBody}
                  ></BlockContent>
                )}
              </Box>
              <Box
                style={{
                  textAlign: 'right',
                }}
              >
                {edge.node.conventionated ? (
                  <Typography
                    variant="overline"
                    style={{ color: `#0a78dd`, textTransform: 'capitalize' }}
                  >
                    Geconventioneerd
                  </Typography>
                ) : (
                  edge.node.conventionated !== null && (
                    <Typography
                      variant="overline"
                      style={{
                        color:
                          edge.node.slug.current === 'selina-reniers'
                            ? `#2fa836`
                            : `#0a78dd`,
                        textTransform: 'capitalize',
                      }}
                    >
                      {edge.node.slug.current === 'selina-reniers'
                        ? 'Medische secretaresse'
                        : 'Gedeconventioneerd'}
                    </Typography>
                  )
                )}
              </Box>
              {/* <TreatmentTags tags={edge.node.treatments} /> */}
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default TherapistsCards
